import { CrossStorage } from '@pismo/sdk-cross-storage';

const crossStorage = CrossStorage({ baseURL: process.env.REACT_APP_BASE_URL });

const decorateData = (data: UserData) => {
  const token = data.token;
  const refreshToken = data.refresh;

  const result: CrossStorageData = {
    token,
    refreshToken,
  };

  return result;
};

export const writeCrossStorageData = (data: UserData) => {
  crossStorage.del(process.env.REACT_APP_CROSS_STORAGE_PREF_KEY);
  return crossStorage.write(
    process.env.REACT_APP_CROSS_STORAGE_AUTH_KEY,
    decorateData(data),
  );
};

export const readCrossStorageData = () => {
  return crossStorage.read(process.env.REACT_APP_CROSS_STORAGE_AUTH_KEY);
};

export const clearCrossStorageData = () => {
  return crossStorage.clear();
};
